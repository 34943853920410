import React from "react"

export const TitleUnderlineWhatsappMasivosOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="252" height="10" viewBox="0 0 252 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.738 8.72465C40.9119 7.97829 3.03683 10.1013 0.866738 9.99622C-1.43164 9.75296 1.3478 3.89821 3.33616 2.96941C4.55483 2.4055 4.46931 2.81461 14.9777 2.4055C14.9777 2.4055 30.8205 1.80842 50.2978 1.11182C50.2978 1.11182 81.5878 0.210661 124.669 0.000575624C128.058 -0.0160101 125.375 0.332289 129.362 0.210661C137.861 -0.0381242 156.355 0.431804 162.79 0.232775C167.953 0.072447 169.065 0.0613902 173.993 0.365461C177.382 0.575547 205.818 1.09523 208.971 0.780103C209.741 0.702703 210.297 0.75246 210.286 0.879617C210.511 0.890674 229.144 1.64809 229.229 1.76419C229.357 1.89687 229.924 1.9798 230.469 1.91346C231.474 1.7863 251.625 2.17883 251.892 2.56583C252.662 3.67154 249.145 8.03911 246.675 9.05083C244.12 10.0957 231.731 8.26025 220.784 8.57538C169.728 6.94445 170.123 7.35909 161.593 7.57471C160.417 7.17112 155.777 8.03911 153.019 7.34803C151.865 7.05502 143.569 6.94445 141.709 7.19324C141.314 7.24852 138.352 7.23746 134.076 7.19876C131.938 7.18218 129.469 7.15453 126.828 7.13242C123.301 7.09925 96.6075 6.94998 93.2614 7.59129C84.2604 6.94998 55.3008 8.33765 46.738 8.72465Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineWhatsappMasivosTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="299" height="10" viewBox="0 0 299 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.5888 10C49.4071 9.07169 4.14324 10.1081 0.853199 9.82831C-1.83281 9.45317 2.45967 2.84689 4.95291 1.87407C5.82682 1.52436 6.84211 1.47351 9.16827 1.63247C12.8567 1.93131 60.5495 1.33997 60.5495 1.33997C61.1793 1.54344 63.0685 0.856744 63.4155 1.10472C64.5079 1.68332 141.811 0.894891 148.404 0.8631C152.401 0.844025 149.239 1.23824 153.943 1.07928C163.967 0.761364 185.815 1.06657 193.411 0.704142C199.49 0.405302 200.801 0.392594 206.635 0.63421C212.598 0.850392 244.304 0.538832 247.928 0.074676C248.815 -0.0524901 249.496 -0.00797814 249.496 0.138263C249.496 0.170054 271.845 0.278142 271.948 0.392591C272.115 0.538832 272.796 0.608769 273.426 0.507036C274.595 0.354437 298.384 -0.198737 298.757 0.227269C299.926 1.44806 296.649 6.64915 293.886 7.91446C291.058 9.26878 275.533 7.74915 263.054 8.56302L204.952 8.64568L192.498 9.15434C191.072 8.71561 185.584 9.82196 182.268 9.03353C180.868 8.72197 170.972 8.67746 168.787 9.00809C166.885 9.26242 116.262 8.62023 111.07 9.50403C101.816 8.76647 64.2894 9.82197 55.5888 10Z" fill="#FFBA00" />
    </svg>
  </span>
)